<template>
  <div class="products">
    <div class="nav_container">
    	<ProductMenu></ProductMenu>
    </div>
    <div class="product">
    	<div class="product_pic">
    		<img :src="product_pic" />
    		<div style="clear: both;width: 100%;height: 0px;" />
    	</div>
    	<div class="product_des">
    		<h1>{{ product?product.name:"" }}</h1>
    		<h2>{{ product?product.introduction:"" }}</h2>
    		<span>{{ product?product.description:"" }}</span>

    		<div class="product_colors" v-if="product?product.has_black_color==1:false">
    			<div class="title">Color</div>
    			<div :class="{product_color:true,color_select:!is_black}" style="border-color:#06A2EC;background-color:#fff;border-width:1px;" @click="set_black(false)"></div>
    			<div :class="{product_color:true,color_select:is_black}" style="background-color:#000;border-color:#000;" @click="set_black(true)"></div>
    		</div>

    		<div class="product_buttons">

    			<a :href="product?product.buy_url:'#'" target="blank"><div class="btn_hover">Buy now</div></a>
    			<a @click="goSupport()"><div class="btn">Support</div></a>

    		</div>
    	</div>
    </div>
    <div class="des_nav">
    		<div :class="{ item: true, select : is_tab_detail}" @click="tab(true)">PRODUCT DETAILS</div>
    		<div :class="{ item: true, select : !is_tab_detail}" @click="tab(false)">SPECIFICATIONS</div>
    </div>
    <div class="product_detail" v-html="product_detail" v-show="is_tab_detail">
    </div>
    <div class="product_params">
    	<img :src="product_params">
    </div>
  </div>
</template>
<script>
import ProductMenu from "@/components/ProductMenu.vue";
export default {
	name:"Products",
	data(){
		return {
			set_product:null,
			is_black:false,
			is_tab_detail:true,
		};
	},
	methods:{
		setId(product){
			this.is_black = false;
			this.is_tab_detail = true;
			this.set_product = product;
			if(product && product.id && product.id>0){
				this.$router.push({
				    query: {
				        id: product.id
				    },
				});
			}
		},
		set_black(black){
			this.is_black = black;
		},
		tab(is_tab_detail){
			this.is_tab_detail = is_tab_detail;
		},
		goSupport(){
			var path = '/support';
			this.$router.push({
	          path: path,
	          query:{
	          	id:this.product.id
	          }
	        });
		}
	},
	computed:{
	  	product(){
	  		return this.set_product;
	  	},
	  	product_pic(){
	  		if(this.product){
	  			if(this.is_black){
	  				return global.SERVER_URL + this.product.black_product_pic;
	  			}else{
	  				return  global.SERVER_URL + this.product.product_pic;
	  			}
	  		}else{
	  			return "";
	  		}
	  	},
	  	product_params(){
			return this.product? global.SERVER_URL + this.product.params_pic:'';
		},
		product_detail(){
			var detail = this.product?this.product.detail:'';
			if(detail){
				 return detail.replace(new RegExp(/\/upload\//,"gm"), global.SERVER_URL + "\/upload\/");
			}else{
				 return "";
			}		
		}
  	},
	components: {
		ProductMenu
	}
};
</script>
<style scoped>
.des_nav .select{
	border-bottom: 3px solid #202B39;
	opacity: 1 !important;
}

.des_nav .item{
	cursor: pointer;
	width:150px;
	height: 30px;
	line-height: 30px;
	margin: 40px;
	float: left;
	font-size: 16px;
	font-family: Muli;
	font-weight: 400;
	color: #202B39;
	opacity: 0.56;
}

.des_nav{
	width: 100%;
	height: 150px;
	border-top: 1px solid #DEDEDE;
	display: flex;
	justify-content: center;
	align-items: center;
}

.product_detail{
	width: 1390px;
	margin: 0 auto;
	min-height: 800px;
}


.product_detail >>> img{
	max-width: 1390px;
	clear: both;
}

.product_buttons{
	width: 100%;
	margin-top: 80px;
	text-align: center;
}

.product_buttons a{
	line-height: 46px;
	cursor: pointer;
}

.btn_hover{
	width: 123px;
	height: 46px;
	background-color: #06A2EC;
	border: 1px solid #06A2EC;
	opacity: 1;
	border-radius: 23px;
	float: left;
	color: #fff;
}

.btn{
	width: 123px;
	height: 46px;
	border: 1px solid #06A2EC;
	opacity: 1;
	border-radius: 23px;
	float: left;
	color: #06A2EC;
	margin-left: 25px;
}

.product_des{
	float: left;
	width: 650px;
	margin-top: 80px;
	text-align: left;
	margin-left: 50px;
}

.product_des h1{
	font-size: 46px;
	font-weight: bold;
	line-height: 56px;
	color: #202B39;
	display: block;
}

.product_des h2{
	font-size: 20px;
	font-weight: 500;
	line-height: 24px;
	color: #202B39;
	display: block;
}

.product_des span{
	font-size: 16px;
	font-weight: 400;
	line-height: 32px;
	color: #202B39;
	opacity: 0.6;
	display: block;
}

.product{
	width: 1390px;
	height: 530px;
	margin: 0px auto;
}

.product_pic{
	width: 650px;
	height: 100%;
	margin-top:20px; 
	float: left;
	clear: both;
	text-align: right;
}

.product_pic img{
	width: 460px;
	height: 460px;
	display: block;
	float: right;
}

.product_colors{
	margin-top: 20px;
	width: 460px;
	height: auto;
	float: left;
	display: flex;
	justify-content:left;
	align-items: center;
}

.product_colors .title{
	color: #999;
	height: 30px;
	font-size: 16px;
	margin-right: 15px;
}


.color_select{
	width: 25px !important;
	height: 25px !important;
	border-color:#06A2EC !important;
	border-width: 2px !important; 
}

.product_color{
	cursor: pointer;
	border-radius: 50%;
	width: 22px;
	height: 22px; 
	border: 2px solid;
	margin: 5px 10px 20px 0px;
}

.product_params{
	width: 1390px;
	margin: auto;
}

.product_params img{
	width: 1390px;
	margin: 0px 0px 50px 0px;
}


.nav_container{
	width: 100%;
	height: 225px;
	background-color: #F2F2F2;
}

.products{
	width: 100%;
	padding:80px 0px 0px 0px;
	margin:0px;
	text-align: center;
}



</style>