<template>
	<div class="support">
		<div class="top">
			<h1>TENVIS Support</h1>
			<span>Our FAQs,support videos,and other resources will help you use <br/>TENVIS products to the best advantage</span>
			<div class="search" style="display: none;">
				<img src="#" />
				<input type="text" name="search" placeholder="search">
			</div>
		</div>
		<div class="menu">
			<h1>Frequently Asked Questions</h1>
			<ProductMenu></ProductMenu>
		</div>

		<div class="content">
			<div class="item" v-for="support in supports">
				<div class="item_title" @click="show(support)">{{support.title}}<span v-text="support.show?'-':'+'"></span></div>
				<div class="item_content" v-show="support.show" v-html="support.content">
				</div>
			</div>
		</div>
		<div class="sub_container">
			<Subscribe></Subscribe>
		</div>
	</div>
</template>
<script>
 import Subscribe from "@/components/Subscribe.vue";
 import ProductMenu from "@/components/ProductMenu.vue";

export default {
	name:"Support",
	data(){
		return { id:0 , supports:[] };
	},
	methods:{
		show(support){
			if(support){
				if(support.show){
					this.$set(support,'show',false);
				}else{
					this.$set(support,'show',true);
				}
			}
		},
		setId(product){
	  		this.id =product.id;
	  		this.getData(this.id);
	  	},
	    getData(product_id){
	      let me = this;
	      this.$http.get(global.SERVER_URL+'/openapi/supports?id='+product_id).then(response => {
	         if(response.data && response.data.code ===0){
	           this.supports = response.data.data;
	         }
	       }
	      , response => {
	         
	      }
	    );
	  }
	},
	components: {
	  	Subscribe,
	  	ProductMenu
	}
};
</script>
<style scoped>
.sub_container{
	padding-bottom: 60px;
}

.item .item_title span{
	cursor: pointer;
	height:100%;
	width: 80px;
	float: right;
	text-align: center;
}

.item .item_title{
	width: auto;
	height: 56px;
	background-color: #F8F8F8;
	border: 1px solid #CCCCCC; /*no*/
	line-height: 56px;
	padding:0px 0px 0px 25px; 
	font-size: 18px;
	color: #202B39;
}

.item .item_content{
	border-left: 1px solid #CCCCCC;/*no*/
	border-right: 1px solid #CCCCCC;/*no*/
	border-bottom: 1px solid #CCCCCC;/*no*/
	padding: 25px 40px;
	font-size: 16px;
	font-family: Muli;
	font-weight: 400;
	line-height: 24px;
	color: #666666;
}

.item{
	margin-top: 25px;
	text-align: left;
}

.content{
	width: 1390px;
	margin: 0 auto;
}

.menu{
	padding-top: 35px;
}

.menu h1{
	font-size: 36px;
	font-family: Muli;
	font-weight: bold;
	line-height: 24px;
	color: #202B39;
}

.search{
	width: 640px;
	height: 56px;
	opacity: 1;
	border-radius: 28px;
	border: 1px solid #ffffff;/*no*/
	margin:auto;
	margin-top: 75px;
	display:flex;
	justify-content: left;
	align-items: center;
}

.search img{
	width: 30px;
	height: 30px;
	margin: 0px 25px;
}

.search input{
	flex: 1;
	margin-right: 20px;
	height: 40px;
	border:0px;
	width:100%;
	color: #ffffff; 
	outline: none;
	appearance: none;
	background: transparent;
	font-size: 16px;
	font-weight: normal;
}

.search input::-webkit-input-placeholder { 
	color: #fff; 
} 
.search input:-moz-placeholder { 
	color: #fff;
} 
.search input::-moz-placeholder { 
	 color: #fff; 
} 
.search input:-ms-input-placeholder { 
	color: #fff; 
}

.support{
	width: 100%;
	margin-top: 80px;
	text-align: center;
}

.top{
	width: 100%;
	height: 306px;
	padding-top: 90px;
	background-color: #06A2EC;
}

.top h1{
	font-size: 36px;
	font-family: Muli;
	font-weight: bold;
	line-height: 63px;
	color: #FFFFFF;
}

.top span{
	font-size: 20px;
	font-family: Muli;
	font-weight: 400;
	line-height: 30px;
	color: #FFFFFF;
	opacity: 0.8;
}
</style>