<template>
  <div class="about">
    <div class="banner">
    	<div class="left"><img src="@/assets/about_banner.jpg" /></div>
    	<div class="right">
    		<h1>Make family warmer,<br/>make life smarter！</h1>
    		<h2>TENVIS Technology Co.，Ltd</h2>
    		<h3>TENVIS Technology Co., Ltd, founded in 2005, is the industry leader of IP Video for Smart Home. TENVIS integrates R&D, production and sales to provide partners with integrated IP video solutions. <br/>TENVIS is committed to providing high-quality products and solutions for daily life. <br/>Since the users have been all over the world in the decades of reform and innovation, TENVIS has become an intelligent security guard for global users to protect their family and property. To experience a more intelligent & delicate life with TENVIS.</h3>

    	</div> 
    </div>
    <div class="devhistory">
    	<div class="des">
    		<h1>{{current.title}}</h1>
    		<div class="des_content">{{current.content}}</div>

    		<div class="historys">
    				<div class="line"></div>
    				<div class="images" v-if="historys">
	    				<div class="image" v-for="history in historys" @click="change(history)">
	    					<img v-if="history.year==current.year" src="@/assets/switch2.png"/>
	    					<img v-else src="@/assets/switch1.png"/>
	    					<span>{{history.year}}</span>
	    				</div>
    				</div>
    		</div>
    	</div>
    	<div class="dev_pic">
    		<img src="@/assets/about_history.jpg">
    	</div>
    	<div style="clear: both;height: 1px;"></div>
    </div>
    <div class="services">
    	<div class="title">
    		<h1>Contact Us</h1>
    		<span>There is now an abundance of readable dummy texts. <br/>These are usually used when</span>
    	</div>
    	<div class="content">
    		<div class="item">
    			<img src="@/assets/online.png" />
    			<p>24/7 Online chat</p>
    			<span>Get an instant reply with our 24/7 Live Chat service!</span>
    			<div class="line"></div>
    			<span>Click on the “Support” widget on the right bottom of the page, click Live chat in work hours to get instant reply or Leave a message and we’ll get back to you ASAP.</span>
    		</div>
    		<div class="item">
    			<img src="@/assets/phone.png" />
    			<p>Phone Calls</p>
    			<span>Call us to get help in real-time!</span>
    			<p>Work Hours:</p>
    			<span>EST 9:00AM-17:00PM Monday-Friday</span>
    			<span class="blue">Call us at +1(833)438-9277 </span>
    		</div>
    		<div class="item">
    			<img src="@/assets/email.png" />
    			<p>Email</p>
    			<span>Send us an email and we’ll get back to you within 24 hours!</span>
    			<span class="blue">support@tenvis.com</span>
    		</div>
    	</div>
    </div>
    <div class="subscribe_container">
	    <Subscribe />
	</div>
  </div>
</template>
<script>
 import Subscribe from "@/components/Subscribe.vue";


export default {
	name:"About",
	data(){
	  return {
	  	historys:[{year:'2005',title : 'Development history',content: 'TENVIS was founded.'},
	  		{year:'2007',title : 'Development history',content: 'TENVIS embarked on the development path of quality and professionalism, and took the lead in passing ISO9001 quality management certification and ISO14001 environmental management certification in the industry.' },
	  		{year:'2010',title : 'Development history',content: 'TENVIS was highly recognized by authoritative organizations and the market. Brand network cameras participated in the Global Sources Hong Kong Electronics Fair for the first time. In the same year, TENVIS was awarded the honor of national "high-tech enterprise".'},
	  		{year:'2015',title : 'Development history',content: 'TENVIS cooperated with Hofan Group to expand overseas online markets and complete strategic transformation.'},
	  		{year:'2019',title : 'Development history',content: 'TENVIS cooperated with Iotex, the pioneer of block chain technology in the United States, and integrated the privacy encryption protocol based on blockchain technology into smart products, thus protecting users\' privacy and becoming an enabler of family safety.'}
	  	],
	  	current:null
	  };
	},
	created(){
		this.current = this.historys[2];
	},
	components: {
	  	Subscribe
	},
	methods:{
		change(his){
			this.current = his;
		}
	}
};

</script>
<style scoped>

.historys .line{
	position: absolute;
	top:0px;
	left: 0px;
	height: 4px;
	background-color: #202B39;
	margin-left:0px;
	margin-top: 42px; 
	opacity: 0.2;
	z-index: 2;
	width:100% !important;
}
.historys .images{
	display: flex;
}

.historys .image{
	margin:0px;
	padding: 0px;
	flex: 1;
	text-align: center;
	z-index: 10;
	cursor: pointer;
}

.historys .image img{
	display: block;
}

.historys .image span{
	color:#999999
}

.historys{
	margin-top: 70px;
	margin-left: 0px !important;
	width: 100% !important;
	height: 85px;
	position: relative;
}

.blue{
	color: #06A2EC !important;
	margin-top: 20px;
}

.services .content .item span{	
	font-size: 18px;
	font-family: Muli;
	font-weight: 400;
	line-height: 28px;
	color: #666666;
	display: block;
}

.services .content .item img{
	width: 75px;
	height: 75px;
}

.services .content .item p{
	font-size: 20px;
	font-family: Muli;
	font-weight: bold;
	line-height: 25px;
	color: #202B39;
	margin:15px 0px;
}

.services .content .item{
	flex: 1;
	text-align: center;
	padding: 0px 40px;
}
.services .content{ 
	width: 1390px;
	margin:0 auto;
	display: flex;
}

.services .title{
	margin:auto;
	width: 100%;
	text-align: center;
	margin-bottom: 115px;
}

.services .title h1{
	font-size: 36px;
	font-family: Muli;
	font-weight: bold;
	line-height: 16px;
	color: #333333;
	margin-bottom: 15px;
}

.services .title span{
	font-size: 24px;
	font-family: Muli;
	font-weight: 400;
	line-height: 30px;
	color: #666666;
}

.services{
	margin: 0px;
	padding: 80px 0px 120px 0px;
	width: 100%;
	text-align: center;
	background-color: #F2F6FB;
}

.subscribe_container{
	width: 100%;
	text-align: center;
	padding-bottom: 100px;
}


.des h1{
	margin-top: 200px;
	margin-left: 260px;
	font-size: 30px;
	font-family: Muli;
	font-weight: bold;
	line-height: 24px;
	color: #333333;
}

.des .des_content{
	height:110px;
}

.des div{
	margin-left: 260px;
	width: 460px;
	font-size: 16px;
	font-family: Muli;
	font-weight: 400;
	line-height: 30px;
	color: #666666;
	flex: 1;
}

.des img{
	width:100%;
	height:90px;
}

.devhistory .dev_pic img{
	width: 100%;
	height: 520px;
	margin-top: 100px;
}

.devhistory .dev_pic{
	float: left;
	width: 840px;
	height: 100%;
	margin:0px;
	padding: 0px;
}

.devhistory .des{
	float: left;
	width: 814px;
	height: 620px;
	margin:0px;
	padding: 0px;
	display: flex;
	flex-direction:column;
}

.devhistory{
	width:100%;
	height:auto;
	margin-bottom:150px; 
}

.banner{
	width:100%;
	height:741px;
}

.banner .left {
    float: left;
    width: 50%;
    height: 100%;
}

.banner .left img {
    width: 100%;
    height: 100%;
}

.banner .right {
    float: left;
    width: 50%;
    height: 100%;
    background:#018FD7;
}

.banner .right h1{
	font-size: 51px;
	font-family: Muli;
	font-weight: bold;
	line-height: 64px;
	color: #FFFFFF;
	margin: 145px 80px 0px 80px;
}

.banner .right h2{
	font-size: 25px;
	font-family: Muli;
	font-weight: bold;
	line-height: 31px;
	color: #FFFFFF;
	margin: 40px 80px 20px 80px;
}

.banner .right h3{
	font-size: 16px;
	font-family: Muli;
	font-weight: 400;
	line-height: 30px;
	color: #FFFFFF;
	margin: 0px 80px 20px 80px;
}
</style>