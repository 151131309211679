<template>
	<div class="activity">
		<img src="@/assets/activity.jpg" class="banner" />
		<div class="content">
			<h1>TENVIS Reviewer Program</h1>
			<p>Be a part of TENVIS and shape the products with us! Your feedback and comments are valuable for us to improve TENVIS products quality and service. That’s why we created the TENVIS Reviewer Program. Every selected TENVIS reviewer will get constant discount code, giveaways, free samples, latest products or other benefits in exchange for your honest reviews and feedbacks.</p>
			<div class="line_gray"></div>
			<div class="note">Note: Not all applicants are guaranteed to be included into the program, but your applications will be kept for future activities. Please provide us with valid and detailed information to increase the possibilities to be selected. We will contact you via email if you are qualified. TENVIS reserves all rights of the program. </div>
			<div class="contact">
				Any questions about the TENVIS Reviewer Program, please contact<a href="mailto:support@tenvis.com">support@tenvis.com</a>
			</div>
			<div class="line"></div>
			<img class="arrow" src="@/assets/activity_arrow.png">
			<h1>Application</h1>
			<div class="form">
				<span>Name(Required)</span>
				<input type="text" name="name" :class="{error:isNameError && isSubmit}" v-model="name" maxlength="100" />
				<span>Emal(Required)</span>
				<input type="text" name="email" :class="{error:isEmailError && isSubmit}" v-model="email" maxlength="100"  />
				<span>Nation(required)</span>
				<input type="text" name="nation" :class="{error:isNationError && isSubmit}" v-model="nation" maxlength="100" />
				<span>Your Amazon profile URL(Required)</span>
				<input type="text" name="url" :class="{error:isUrlError && isSubmit}" maxlength="300" v-model="url"  />
				<span>Do you have an Amazon Alexa product?</span>	
				<div class="name_input">
					<input type="radio" name="product" v-model="product" value="1"> <i>I have got</i>
					<input type="radio" name="product" v-model="product" value="0"> <i>I do not have</i>
				</div>
				<span>Tell us why you want to join our program (optional)</span>
				<textarea name="content" maxlength="2000" v-model="content"></textarea>
				<p>Enter your Facebook, instagram, twitter, Youtube or other URL</p>
				<div class="submit" @click="submit">Submit</div>
			</div>
		</div>
		  <div class="subscribe_container">
		    <Subscribe />
		</div>
	</div>
</template>
<script>
import Subscribe from "@/components/Subscribe.vue";

export default {
	name:"Activity",
	data(){
		return {
			name:'',
			email:'',
			nation:'',
			url:'',
			product:'1',
			content:'',
			isSubmit:false,
		};
	}
	,
	computed:{
		isNameError(){
			if(this.name){
			  return false;
			}else{
			  return true;
			}
		},
		isNationError(){
			if(this.nation){
			  return false;
			}else{
			  return true;
			}
		},
		isUrlError(){
			if(this.url){
			  return false;
			}else{
			  return true;
			}
		},
		isEmailError(){
			if(this.email){
				var reg = /^[a-zA-Z0-9_\.\-]+\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
				if(reg.test(this.email)){
					return false;
				}
			}
			return true;
		},
		setProduct(product){
			this.product = product;
		}
	},
	methods:{
		submit(){
			this.isSubmit = true;
			if(this.isNameError || this.isNationError ||  this.isEmailError ||  this.isUrlError){
				return;
			}
			this.isSubmit = false;

			this.$http.post(global.SERVER_URL+'/openapi/activity',{name:this.name,nationality:this.nation,email:this.email,want_product:this.product,url:this.url,content:this.content},{emulateJSON:true}).then(response => {
	         console.log(response.data);
	         if(response.data && response.data.code ===0){
	         	this.name = "";
	         	this.nation = "";
	         	this.email = "";
	         	this.content = "";
	         	this.url = "";
	         	this.product = '1';
	         	this.$toast('Success')
	         }else{
	         	this.$toast('submit failed, please try again.')
	         }
	      }, response => {
	         this.$toast('request failed, please try again.')
	      });
		}
	},
	components: {
		Subscribe
	}
};
</script>
<style scoped>

.error{
	border-color: red !important;
}

.arrow{
	width: 20px;
	height: 14px;
	margin-bottom: 10px;
}

.subscribe_container{
	width: 100%;
	text-align: center;
	padding-bottom: 100px;
}

.line_gray{
	width: 1390px;
	height: 1px;
	background: #EEEEEE;
	margin:50px 0px;
}

.note{
	margin: auto;
	width: 1243px;
	height: 71px;
	font-size: 18px;
	font-family: Muli;
	font-weight: 400;
	line-height: 24px;
	color: #999999;
	margin-bottom: 30px;
}

.contact{
	font-size: 18px;
	font-family: Muli;
	font-weight: 400;
	line-height: 18px;
	margin-bottom: 100px;
}

.contact a{
	color:#328CFF;
	text-decoration: none;
	padding-left: 20px;

}

.floatleft{
	float: left;
}

.floatright{
	float: right;
}

.submit{
	cursor: pointer;
	width: 100%;
	height: 56px;
	background: #202B39;
	font-size: 24px;
	font-family: Ebrima;
	line-height: 56px;
	color: #FFFFFF;
	margin-bottom: 100px;
	text-align: center;
	vertical-align: middle;
}

.name_input{
	width: 960px;
	display: flex;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 30px;
}

.name_input i{
	font-size: 20px;
	font-family: Ebrima;
	color: #999999;
	font-style: normal;
	margin-right: 30px;
	margin-left: 15px;
}


.name_input input{
	width: 22px !important;
	height: 22px !important;
}

.form textarea{
	width: 940px;
	margin:15px 0px;
	padding:10px 10px;
	height: 185px;
}

.form input{
	width: 940px;
	height: 48px;
	border: 1px solid #999999;
	outline: none;
	margin:15px 0px;
	padding:0px 10px;
}

.form p{
	font-size: 16px;
	font-family: Ebrima;
	line-height: 24px;
	color: #999999;
	margin-top: 10px;
	margin-bottom: 50px;
}

.form span{
	font-size: 24px;
	font-family: Ebrima;
	line-height: 24px;
	color: #333333;
	display: block;
	margin-top: 20px;
}

.form{
	width: 960px;
	margin: auto;
	text-align: left;
}

h1{
	font-size: 36px;
	font-family: Muli;
	font-weight: bold;
	line-height: 24px;
	color: #202B39;
}

p{
	margin-top: 15px;
	width: 1218px;
	height: 60px;
	font-size: 24px;
	font-family: Muli;
	font-weight: 400;
	line-height: 30px;
	color: #666666;
	margin:auto;
	margin-bottom: 100px;
}

.line{
	width: 1390px;
	height: 6px;
	background: #018FD7;
	opacity: 1;
	margin:auto;
}

.activity{
	padding-top:80px;
	width: 100%; 
	text-align: center;
}

.content{
	margin-top: 80px;
}

.banner{
	width: 100%;
	height: 712px;
}

</style>