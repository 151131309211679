<template>
	<div class="joinus">
		<img src="@/assets/joinus.jpg" class="banner" />
		<div class="content">
			<h1>Be A TENVIS Influencer</h1>
			<p>Being a TENVIS Influencer, free products, latest promotions, hot coupons and deals will be provided to facilitate your promotions.Get more popularity by promoting our brand, come to grow together with us!</p>
			<div class="line"></div>
			<img class="arrow" src="@/assets/joinus_arrow.png">
			<h1>I’m An Influencer, Contact Me!</h1>
			<div class="form">
				<span>Name</span>
				<div class="name_input">
					<input type="text" :class="{error:isFirstError&&isSubmit}" name="first" placeholder="first" v-model="first" maxlength="100" />
					<input class="floatright" :class="{error:isLastError && isSubmit}" type="text" name="last" placeholder="last"  v-model="last" maxlength="100" />
				</div>
				<span>Email</span>
				<input type="text" name="email" :class="{error:isEmailError && isSubmit}" v-model="email" maxlength="100" />
				<span>Country</span>
				<input type="text" name="country" v-model="country" maxlength="100" />
				<span>URL</span>
				<textarea name="url"  v-model="url" maxlength="2000"></textarea>
				<p>Enter your Facebook, instagram, twitter, Youtube or other URL</p>
				<div class="submit" @click="submit">Submit</div>
			</div>
		</div>
		 <div class="subscribe_container">
		   <Subscribe />
		</div>
	</div>
</template>
<script>
import Subscribe from "@/components/Subscribe.vue";

export default {
	name:"Joinus",
	data(){
		return {
			first:'',
			last:'',
			email:'',
			country:'',
			url:'',
			isSubmit: false,
		};
	},
	computed:{
		isFirstError(){
			if(this.first){
			  return false;
			}else{
			  return true;
			}
		},
		isLastError(){
			if(this.last){
			  return false;
			}else{
			  return true;
			}
		},
		isEmailError(){
			if(this.email){
				var reg = /^[a-zA-Z0-9_\.\-]+\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
				if(reg.test(this.email)){
					return false;
				}
			}
			return true;
		}
	},
	methods:{
		submit(){
			this.isSubmit = true;
			if(this.isLastError || this.isFirstError ||  this.isEmailError){
				return;
			}
			this.isSubmit = false;

			this.$http.post(global.SERVER_URL+'/openapi/joinus',{first_name:this.first,last_name:this.last,email:this.email,country:this.country,url:this.url},{emulateJSON:true}).then(response => {
	         console.log(response.data);
	         if(response.data && response.data.code ===0){
	         	this.first = "";
	         	this.last = "";
	         	this.email = "";
	         	this.country = "";
	         	this.url = "";
	         	this.$toast('Success')
	         }else{
	         	this.$toast('submit failed, please try again.')
	         }
	      }, response => {
	         this.$toast('request failed, please try again.')
	      });
		}
	},
	components: {
		Subscribe
	}
};
</script>
<style scoped>
.arrow{
	width: 20px;
	height: 14px;
	margin-bottom: 10px;
}

.error{
	border-color: red !important;
}

.subscribe_container{
	width: 100%;
	text-align: center;
	padding-bottom: 100px;
}

.floatleft{
	float: left;
}

.floatright{
	float: right;
}

.submit{
	cursor: pointer;
	width: 100%;
	height: 56px;
	background: #202B39;
	font-size: 24px;
	font-family: Ebrima;
	line-height: 56px;
	color: #FFFFFF;
	margin-bottom: 100px;
	text-align: center;
	vertical-align: middle;
}

.name_input{
	width: 960px;
}

.name_input input{
	width: 440px !important;
}

.form textarea{
	width: 940px;
	margin:15px 0px;
	padding:10px 10px;
	height: 185px;
}

.form input{
	width: 940px;
	height: 48px;
	border: 1px solid #999999;
	outline: none;
	margin:15px 0px;
	padding:0px 10px;
}

.form p{
	font-size: 16px;
	font-family: Ebrima;
	line-height: 24px;
	color: #999999;
	margin-top: 10px;
	margin-bottom: 50px;
}

.form span{
	font-size: 24px;
	font-family: Ebrima;
	line-height: 24px;
	color: #333333;
	display: block;
	margin-top: 20px;
}

.form{
	width: 960px;
	margin: auto;
	text-align: left;
}

h1{
	font-size: 36px;
	font-family: Muli;
	font-weight: bold;
	line-height: 24px;
	color: #202B39;
}

p{
	margin-top: 15px;
	width: 1218px;
	height: 60px;
	font-size: 24px;
	font-family: Muli;
	font-weight: 400;
	line-height: 30px;
	color: #666666;
	margin:auto;
	margin-bottom: 100px;
}

.line{
	width: 1390px;
	height: 6px;
	background: #06A2EC;
	opacity: 1;
	margin:auto;
}

.joinus{
	padding-top:80px;
	width: 100%; 
	text-align: center;
}

.content{
	margin-top: 80px;
}

.banner{
	width: 100%;
	height: 712px;
}

</style>