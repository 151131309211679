<template>
	<div class="nav">
    		<div class="item" v-for="(product,index) in this.$store.state.products" @click="setId(product.id)">
    			<img :src="get_menu_pic(product)" />
    			<span :class="{'blue':selected_id == product.id}">{{product.name}}</span>
    		</div>
    </div>
</template>
<script>
export default {
  name: "ProductMenu",
  data(){
  	return { id : 0 };
  },
  created(){
    if(this.$route.query && this.$route.query.id){
      this.id = this.$route.query.id;
    }
	  this.changeProduct();	
  },
  methods:{
  	setId(id){
  		this.id = id;
  	},
  	changeProduct(){
  		console.log('changeProduct')
      console.log('selected_id:'+this.selected_id)
  		if(this.products && this.products.length>0){
  				if(this.selected_id>0){
	  				for(var i =0 ;i< this.products.length;i++){
				  		if(this.products[i].id == this.selected_id){
				  			this.$parent.setId(this.products[i]);
				  		}
				  	}
				}else{
			  		this.$parent.setId(this.products[0]);
				}
		} 	
  	},
  	get_menu_pic(product){
  		if(product.menu_pic){
  			return global.SERVER_URL+ product.menu_pic;
  		}else{
  			return "";
  		}
  		
  	}
  },
  computed:{
  	selected_id(){
  		if(this.id){
  			return this.id;
  		}else if(this.products && this.products.length>0 ){
  			return this.products[0].id;
  		}else{
  			return 0;
  		}
  	},
  	products(){
  		return this.$store.state.products;
  	}
  },
  watch:{
  	selected_id(val) {
  		this.changeProduct();	
     },
     products(val){
     	this.changeProduct();
     },
     $route(to,from){
        if(to.query && to.query.id){
          this.id = to.query.id;
        }
     }
  }
};
</script>
<style scoped="">
.blue{
	color: #06A2EC !important;
}

.nav .item img{
	width: 80px;
	height: 80px;
	display: block;
	margin-bottom: 20px;
}

.nav .item{
	padding:0px 50px;
	cursor: pointer;
}

.nav{
	width: 100%;
	height: 200px;
	background-color: #FFF;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}
</style>